<template>
  <div class="p-1 mt-2 text-xs text-red-400">
    <slot></slot>
  </div>
</template>

<script>
    export default {
      name: 'FormError',
    };
</script>
